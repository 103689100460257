


















import { Notify } from "vant";
import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import Stroage from "../../util/Storage";
import { GetNoticeSuc } from "../../Api/Basics/index";
import { NoticeSucType } from "../../Type/index";
import { Vue, Component } from "vue-property-decorator";
import ZoomPage from "@/impView/PageSafety";

interface InformSucType<T = string> {
  loadingShow: boolean;
  time: T;
  userId: number | T;
  token: T;
  data: NoticeSucType;

  handleUpData(): void;
  handleError(data: string): void;
}

@Component({ name: "InformSuc",components:{ HeadTop } })
export default class InformSuc extends ZoomPage implements InformSucType {
  userId: string | number = "";
  token = "";
  data: any = {};
  loadingShow = true;
  time = require("$icon/Basics/time.png");
  mounted() {
    this.userId = Stroage.GetData_ && Stroage.GetData_("userId");
    this.token = Stroage.GetData_ && Stroage.GetData_("token");
    this.handleUpData();
  }

  handleUpData() {
    GetNoticeSuc(
      {
        userId: this.userId,
        token: this.token,
      },
      {
        id: this.$route.query.id as string,
      }
    ).then((res: any) => {
      this.loadingShow = false;
      if (res.message.code === "200") {
        this.data = res.data;
      } else {
        this.handleError(res.message.msg);
      }
    });
  }

  handleError(data: string) {
    let str = "";
    if (data.length) {
      str = data;
    } else {
      str = "网络繁忙...";
    }
    Notify({
      message: str,
      color: "white",
      background: "rgba(0,0,0,.6)",
    });
  }
}
